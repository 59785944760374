import { NextRequest, NextResponse } from 'next/server'

export const formatarNome = (nome: string) => {
  return String(nome)
    .toUpperCase()
    .replaceAll(' DOS ', ' ')
    .replaceAll(' DAS ', ' ')
    .replaceAll(' DES ', ' ')
    .replaceAll(' DO ', ' ')
    .replaceAll(' DA ', ' ')
    .replaceAll(' DE ', ' ')
    .replaceAll('-', ' ')
    .replaceAll('.', ' ')
    .replaceAll('  ', ' ')
    .trim()
}

export const stringToColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const color = '#' + (hash & 0x00ffffff).toString(16).padStart(6, '0')
  return color
}

export const getInitials = (name: string) => {
  const names = name.trim().split(' ')
  const initials =
    names[0].charAt(0).toUpperCase() +
    (names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : '')
  return initials
}

export const withLogging = (
  handler: (req: NextRequest) => Promise<NextResponse>
) => {
  return async (req: NextRequest) => {
    const reqClone = req.clone()
    const requestBody = await reqClone.text()

    console.log(
      `[INCOMING] ${req.cookies.get('sessionId')?.value ?? '-'} ${
        req.method
      } - ${req.nextUrl.toString()} - ${requestBody.slice(0, 200)}${
        requestBody.length > 200 ? '...' : ''
      }`
    )

    const res = await handler(req)

    const resClone = res.clone()
    const outputBody = await resClone.text()

    const truncatedBody =
      outputBody.length > 200 ? `${outputBody.slice(0, 200)}...` : outputBody

    const logItems = [
      '[OUTGOING]',
      req.cookies.get('sessionId')?.value ?? '-',
      req.method,
      req.nextUrl.toString(),
      res.status,
      truncatedBody,
    ]

    console.log(logItems.join(' - '))

    return res
  }
}
