import { Detector } from '../detector'
import { DetectorType } from '../../utils/enum'
import { log } from '../../utils/log'
import { IS } from '../../utils/util'

export default class extends Detector {
  lastTime: number = 0
  reg: RegExp = /./

  constructor() {
    super({
      type: DetectorType.RegToString,
      enabled: IS.qqBrowser || IS.firefox,
    })
  }

  init() {
    this.lastTime = 0
    this.reg = /./
    log(this.reg)
    this.reg.toString = () => {
      if (IS.qqBrowser) {
        const time = new Date().getTime()
        if (this.lastTime && time - this.lastTime < 100) {
          this.onDevToolOpen()
        } else {
          this.lastTime = time
        }
      } else if (IS.firefox) {
        this.onDevToolOpen()
      }
      return ''
    }
  }

  detect() {
    log(this.reg)
  }
}
