'use client';

import React, { useState, useEffect } from 'react';
import { SettingOutlined, LogoutOutlined, LoginOutlined, FileOutlined, EnvironmentOutlined, TeamOutlined, ExperimentOutlined, FormOutlined, ShopOutlined } from '@ant-design/icons';
import axios from 'axios';
import { usePathname, useRouter } from 'next/navigation';
import { Menu, Dropdown, Avatar, Modal, message, Button } from 'antd';
import { useTranslations } from 'next-intl';
import FrontendGuard from '@/utils/FrontendGuard';
import { getInitials, stringToColor } from '@/utils';
interface User {
  nome: string;
}
message.config({
  duration: 2,
  maxCount: 3
});
export default function HeaderComponent({
  disableSecurity = false,
  disableAuthRedirect = false
}) {
  const t = useTranslations();
  useEffect(() => {
    if (disableSecurity === false && process.env.NODE_ENV !== 'development') {
      FrontendGuard();
    }
  }, [disableSecurity]);
  const router = useRouter();
  const pathname = usePathname();
  const [user, setUser] = useState<User | null>(null);
  const [hasAuthenticatedBefore, setHasAuthenticatedBefore] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const publicRoutes = ['/pt', '/pt/autenticar', '/pt/termos', '/pt/seguranca', '/en', '/en/autenticar', '/en/termos', '/en/seguranca'];
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('/api/conta');
        setUser(response.data.user);
        setHasAuthenticatedBefore(true);
      } catch (error) {
        setUser(null);
        if (!disableAuthRedirect) {
          if (!publicRoutes.includes(pathname)) {
            router.push('/autenticar');
          }
        }
      }
    };
    const pingToken = async () => {
      try {
        await axios.get('/api/conta');
        if (!hasAuthenticatedBefore) {
          setHasAuthenticatedBefore(true);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          if (hasAuthenticatedBefore) {
            setUser(null);
            message.error(t('sessionExpired'));
            if (!publicRoutes.includes(pathname)) {
              router.push('/autenticar');
            }
          }
        }
      }
    };
    fetchUser();
    const interval = setInterval(pingToken, 5 * 60000);
    return () => clearInterval(interval);
  }, [router, hasAuthenticatedBefore]);
  const handleNavigation = (path: string, disabled: boolean) => {
    if (!user && !publicRoutes.includes(path)) {
      message.info(t('authRequired'));
    } else if (disabled) {
      setIsModalVisible(true);
    } else {
      router.push(path);
    }
  };
  const handleLogin = () => {
    router.push('/autenticar');
  };
  const handleAccount = () => {
    router.push('/conta');
  };
  const handleLogout = async () => {
    try {
      await axios.post('/api/desautenticar');
      message.success(t('logoutSuccess'));
      setUser(null);
      router.push('/');
    } catch (error) {
      message.error(t('logoutError'));
    }
  };
  const menuItems = [{
    key: 'pesquisas',
    label: t('research'),
    path: '/pesquisas',
    disabled: false,
    icon: <FormOutlined />
  }, {
    key: 'genetica',
    label: t('genetics'),
    path: '/genetica',
    disabled: false,
    icon: <ExperimentOutlined />
  }, {
    key: 'documentos',
    label: t('documents'),
    path: '/documentos',
    disabled: true,
    icon: <FileOutlined />
  }, {
    key: 'locais',
    label: t('locations'),
    path: '/locais',
    disabled: true,
    icon: <EnvironmentOutlined />
  }, {
    key: 'pessoas',
    label: t('people'),
    path: '/pessoas',
    disabled: true,
    icon: <TeamOutlined />
  }, {
    key: 'produtos',
    label: t('products'),
    path: '/produtos',
    disabled: false,
    icon: <ShopOutlined />
  }];
  const userMenu = <Menu>
      {menuItems.map(item => <Menu.Item key={item.key} onClick={() => handleNavigation(item.path, item.disabled)} style={{
      opacity: item.disabled ? 0.5 : 1
    }} icon={item.icon}>
          {item.label}
        </Menu.Item>)}
      <Menu.Item key="ancestorproject" onClick={() => {
      router.push('/ancestorproject');
    }} icon={<ExperimentOutlined />}>
        {t('ancestorProject')}
      </Menu.Item>
      <Menu.Item key="conta" onClick={handleAccount} icon={<SettingOutlined />}>
        {t('settings')}
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        {t('logout')}
      </Menu.Item>
    </Menu>;
  return <>
      <header className="bg-white flex flex-col items-center justify-center p-4 space-y-4 md:space-y-0 md:flex-row md:justify-between">
        <div onClick={() => handleNavigation('/', false)} style={{
        color: 'rgb(0, 119, 182)'
      }} className="text-2xl font-bold cursor-pointer">
          <img src="/assets/logo.svg" className="w-[300px] h-[50px]" />
        </div>

        <nav className="flex flex-wrap items-center justify-center space-x-4">
          {menuItems.map((item, index) => <React.Fragment key={item.key}>
              <button onClick={() => handleNavigation(item.path, item.disabled)} className={`flex items-center space-x-2 px-4 py-2 text-base font-medium border-b-2 transition-all duration-300 ${item.disabled ? 'opacity-50 cursor-default border-transparent' : 'hover:text-blue-600 hover:border-blue-600 border-transparent'}`}>
                <span className="text-lg">{item.icon}</span>
                <span>{item.label}</span>
              </button>

              {index < menuItems.length - 1 && <span className="mx-2 text-gray-300">|</span>}
            </React.Fragment>)}
        </nav>

        <div className="flex items-center justify-center">
          {user ? <Dropdown overlay={userMenu} placement="bottomRight">
              <Avatar size="large" style={{
            backgroundColor: stringToColor(user.nome),
            cursor: 'pointer'
          }}>
                {getInitials(user.nome)}
              </Avatar>
            </Dropdown> : <Button type="primary" icon={<LoginOutlined />} onClick={handleLogin} className="flex items-center">
              {t('authenticate')}
            </Button>}
        </div>
      </header>

      <Modal title={t('featureUnavailable')} visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)} okText={t('understood')} cancelText={t('close')} cancelButtonProps={{
      style: {
        display: 'none'
      }
    }} data-sentry-element="Modal" data-sentry-source-file="Cabecalho.tsx">
        <p>{t('featureComingSoon')}</p>
      </Modal>
    </>;
}