'use client';

import React, { useState, useEffect } from 'react';
import { Layout, Typography, Tooltip, Drawer, Button } from 'antd';
import { UserOutlined, FileOutlined, UserAddOutlined, InstagramOutlined, FacebookOutlined, GlobalOutlined } from '@ant-design/icons';
import { usePathname, useRouter } from 'next/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { version } from '@/package.json';
const {
  Footer
} = Layout;
const {
  Text
} = Typography;
export default function FooterComponent() {
  const t = useTranslations('FooterComponent');
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const [metrics, setMetrics] = useState({
    usuariosRegistradosOnline: 0,
    usuariosAnonimosOnline: 0,
    usuariosRegistrados: 0,
    arquivosEnviados: 0,
    emailsOnline: []
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const openDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);
  useEffect(() => {
    const fetchMetrics = async () => {
      const res = await fetch('/api/estatisticas');
      const data = await res.json();
      setMetrics({
        usuariosRegistradosOnline: data.onlineUsers.usuariosRegistradosOnline,
        usuariosAnonimosOnline: data.onlineUsers.usuariosAnonimosOnline,
        usuariosRegistrados: data.usuariosRegistrados,
        arquivosEnviados: data.arquivosEnviados,
        emailsOnline: data.onlineUsers.emailsOnline
      });
    };
    fetchMetrics();
    const interval = setInterval(fetchMetrics, 5 * 60000);
    return () => clearInterval(interval);
  }, []);
  const handleLanguageChange = (value: string) => {
    const segments = pathname.split('/').filter(Boolean);
    segments[0] = value;
    const newPath = `/${segments.join('/')}`;
    router.push(newPath);
  };
  return <>
      <Footer style={{
      backgroundColor: '#1c1c1c',
      color: '#fff',
      padding: '40px'
    }} data-sentry-element="Footer" data-sentry-source-file="Rodape.tsx">
        <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: 'auto'
      }}>
          <div style={{
          flex: 1,
          minWidth: '200px',
          textAlign: 'left',
          marginBottom: '20px'
        }}>
            <Typography.Title level={4} style={{
            color: '#fff'
          }} data-sentry-element="unknown" data-sentry-source-file="Rodape.tsx">
              {t('socialMedia')}
            </Typography.Title>
            <div style={{
            display: 'flex',
            gap: '10px'
          }}>
              <a href="https://facebook.com/apoiogenealogico" target="_blank" rel="noopener noreferrer">
                <FacebookOutlined style={{
                fontSize: '30px',
                color: '#fff'
              }} data-sentry-element="FacebookOutlined" data-sentry-source-file="Rodape.tsx" />
              </a>
              <a href="https://instagram.com/apoiogenealogico" target="_blank" rel="noopener noreferrer">
                <InstagramOutlined style={{
                fontSize: '30px',
                color: '#fff'
              }} data-sentry-element="InstagramOutlined" data-sentry-source-file="Rodape.tsx" />
              </a>
            </div>
          </div>
          <div style={{
          flex: 1,
          minWidth: '200px',
          textAlign: 'left',
          marginBottom: '20px'
        }}>
            <Typography.Title level={4} style={{
            color: '#fff'
          }} data-sentry-element="unknown" data-sentry-source-file="Rodape.tsx">
              {t('contact')}
            </Typography.Title>
            <Text style={{
            color: '#fff',
            display: 'block'
          }} data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              contato@solbra.com.br
            </Text>
          </div>
          <div style={{
          flex: 1,
          minWidth: '200px',
          textAlign: 'left',
          marginBottom: '20px'
        }}>
            <Typography.Title level={4} style={{
            color: '#fff'
          }} data-sentry-element="unknown" data-sentry-source-file="Rodape.tsx">
              {t('legal')}
            </Typography.Title>
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="https://apoiogenealogi.co/termos" style={{
              color: '#fff'
            }}>
                {t('servicePolicy')}
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="https://solbra.com.br/terms" style={{
              color: '#fff'
            }}>
                {t('termsOfUse')}
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="https://solbra.com.br/privacy" style={{
              color: '#fff'
            }}>
                {t('privacyPolicy')}
              </a>
            </Text>
          </div>
          <div style={{
          flex: 1,
          minWidth: '200px',
          textAlign: 'left',
          marginBottom: '20px'
        }}>
            <Typography.Title level={4} style={{
            color: '#fff'
          }} data-sentry-element="unknown" data-sentry-source-file="Rodape.tsx">
              {t('technical')}
            </Typography.Title>

            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="http://status.solbra.com.br" style={{
              color: '#fff'
            }}>
                {t('serviceUpdates')}
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="#privacy-policy" data-cc="show-preferencesModal" style={{
              color: '#fff'
            }}>
                {t('cookieManagement')}
              </a>
            </Text>
          </div>
          <div style={{
          flex: 1,
          minWidth: '200px',
          textAlign: 'left',
          marginBottom: '20px'
        }}>
            <Typography.Title level={4} style={{
            color: '#fff'
          }} data-sentry-element="unknown" data-sentry-source-file="Rodape.tsx">
              {t('howtoupload')}
            </Typography.Title>

            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/genera" style={{
              color: '#fff'
            }}>
                Genera
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/ancestry" style={{
              color: '#fff'
            }}>
                Ancestry
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/23andme" style={{
              color: '#fff'
            }}>
                23andme
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/myheritage" style={{
              color: '#fff'
            }}>
                MyHeritage
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/livingdna" style={{
              color: '#fff'
            }}>
                LivingDNA
              </a>
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos/tellmegen" style={{
              color: '#fff'
            }}>
                TellmeGen
              </a>
            </Text>
            <br />
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a href="/comobaixardadosbrutos" style={{
              color: '#fff'
            }}>
                Qual teste de DNA fazer?
              </a>
            </Text>
          </div>
        </div>

        <div style={{
        textAlign: 'center',
        marginTop: '20px'
      }}>
          <Text style={{
          fontSize: '10px',
          color: '#fff'
        }} data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
            {t('madeBy')}{' '}
            <Text style={{
            fontWeight: 'bold',
            fontSize: '11px',
            color: '#fff'
          }} data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
              <a style={{
              fontWeight: 'bold',
              fontSize: '11px',
              color: '#fff'
            }} href="https://solbra.com.br" target="_blank" rel="noopener noreferrer">
                SOLBRA
              </a>
              <Text style={{
              fontSize: '8px',
              verticalAlign: 'super',
              color: '#fff'
            }} data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
                ©
              </Text>
            </Text>{' '}
            {t('slogan')}
          </Text>
          <br />
          <Text style={{
          fontWeight: 'bold',
          fontSize: '11px',
          color: '#fff'
        }} data-sentry-element="Text" data-sentry-source-file="Rodape.tsx">
            {version}
          </Text>
        </div>

        <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
      }}>
          <Button onClick={openDrawer} style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: '#1f1f1f',
          color: '#e0e0e0',
          border: 'none',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
          borderRadius: '8px',
          padding: '8px 12px',
          fontSize: '16px',
          cursor: 'pointer'
        }} data-sentry-element="Button" data-sentry-source-file="Rodape.tsx">
            <GlobalOutlined style={{
            fontSize: '18px'
          }} data-sentry-element="GlobalOutlined" data-sentry-source-file="Rodape.tsx" />
            {locale === 'en' ? 'English' : 'Português'}
          </Button>

          <Drawer title="Select Language" placement="right" onClose={closeDrawer} visible={drawerVisible} width={300} bodyStyle={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          borderRadius: '8px'
        }} headerStyle={{
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #e0e0e0',
          fontSize: '20px',
          fontWeight: 'bold'
        }} data-sentry-element="Drawer" data-sentry-source-file="Rodape.tsx">
            <div onClick={() => {
            handleLanguageChange('pt');
            closeDrawer();
          }} style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '3px 5px 3px 10px',
            borderRadius: '8px',
            backgroundColor: '#e0e0e0',
            transition: 'all 0.3s',
            marginBottom: '10px'
          }}>
              <span role="img" aria-label="Portuguese" style={{
              fontSize: '24px',
              marginRight: '10px'
            }}>
                🇧🇷
              </span>
              Português
            </div>

            <div onClick={() => {
            handleLanguageChange('en');
            closeDrawer();
          }} style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '3px 5px 3px 10px',
            borderRadius: '8px',
            backgroundColor: '#e0e0e0',
            transition: 'all 0.3s'
          }}>
              <span role="img" aria-label="English" style={{
              fontSize: '24px',
              marginRight: '10px'
            }}>
                🇺🇸
              </span>
              English
            </div>
          </Drawer>
        </div>
      </Footer>

      <div style={{
      position: 'fixed',
      bottom: '75px',
      right: '20px',
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }}>
        <Tooltip title={t('onlineUsers')} data-sentry-element="Tooltip" data-sentry-source-file="Rodape.tsx">
          <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '130px',
          padding: '8px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          fontSize: '12px',
          flexDirection: 'column'
        }}>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '6px'
          }}>
              <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <UserAddOutlined style={{
                color: '#4CAF50',
                fontSize: '16px'
              }} data-sentry-element="UserAddOutlined" data-sentry-source-file="Rodape.tsx" />
                <span style={{
                fontSize: '12px',
                color: '#333',
                marginLeft: '4px'
              }}>
                  {metrics.usuariosRegistradosOnline}
                </span>
              </div>
              <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <UserOutlined style={{
                color: '#FF5722',
                fontSize: '16px'
              }} data-sentry-element="UserOutlined" data-sentry-source-file="Rodape.tsx" />
                <span style={{
                fontSize: '12px',
                color: '#333',
                marginLeft: '4px'
              }}>
                  {metrics.usuariosAnonimosOnline}
                </span>
              </div>
            </div>

            <div style={{
            maxHeight: '100px',
            overflowY: 'scroll',
            width: '100%',
            padding: '5px',
            backgroundColor: '#f7f7f7',
            borderRadius: '4px',
            fontSize: '10px',
            textAlign: 'left'
          }}>
              {metrics.emailsOnline.map((email, index) => <div key={index} style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
                  {email}
                </div>)}
            </div>
          </div>
        </Tooltip>

        <Tooltip title={t('registeredUsers')} data-sentry-element="Tooltip" data-sentry-source-file="Rodape.tsx">
          <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '130px',
          padding: '5px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
            <span style={{
            fontSize: '16px',
            color: '#000'
          }}>
              {metrics.usuariosRegistrados}
            </span>
            <UserOutlined style={{
            color: '#1890ff',
            fontSize: '18px',
            marginLeft: '8px'
          }} data-sentry-element="UserOutlined" data-sentry-source-file="Rodape.tsx" />
          </div>
        </Tooltip>

        <Tooltip title={t('filesInSystem')} data-sentry-element="Tooltip" data-sentry-source-file="Rodape.tsx">
          <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '130px',
          padding: '5px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
            <span style={{
            fontSize: '16px',
            color: '#000'
          }}>
              {metrics.arquivosEnviados}
            </span>
            <FileOutlined style={{
            color: '#1890ff',
            fontSize: '18px',
            marginLeft: '8px'
          }} data-sentry-element="FileOutlined" data-sentry-source-file="Rodape.tsx" />
          </div>
        </Tooltip>
      </div>

      <style jsx>{`
        @keyframes blink {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </>;
}