import { config } from './config'

export function closeWindow() {
  if (config.url) {
    window.location.href = config.url
  } else if (config.rewriteHTML) {
    try {
      document.documentElement.innerHTML = config.rewriteHTML
    } catch (e) {
      document.documentElement.innerText = config.rewriteHTML
    }
  } else {
    try {
      window.opener = null
      window.open('', '_self')
      window.close()
      window.history.back()
    } catch (e) {
      console.log(e)
    }
    setTimeout(() => {
      window.location.href = `https://apoiogenealogi.co/seguranca`
    }, 500)
  }
}
