import { closeWindow } from '../utils/close-window'
import { config } from '../utils/config'
import { DetectorType } from '../utils/enum'
import {
  clearDDInterval,
  clearDDTimeout,
  registInterval,
} from '../utils/interval'
import { markDevToolOpenState } from '../utils/open-state'

export interface IDetectorConfig {
  type: DetectorType
  enabled?: boolean
}

export abstract class Detector {
  type: DetectorType = DetectorType.Unknown
  enabled: boolean = true

  constructor({ type, enabled = true }: IDetectorConfig) {
    this.type = type
    this.enabled = enabled
    if (this.enabled) {
      registInterval(this)
      this.init()
    }
  }

  onDevToolOpen() {
    console.warn(
      `\u001b[31m⚠️ Atenção! ⚠️\u001b[0m \u001b[33m O uso de engenharia reversa é estritamente proibido nesta plataforma! 🚫\u001b[0m`
    )
    if (config.clearIntervalWhenDevOpenTrigger) {
      clearDDInterval()
    }
    clearDDTimeout()
    config.ondevtoolopen(this.type, closeWindow)
    markDevToolOpenState(this.type)
  }

  init() {}
  abstract detect(time: number): void
}
