import { Detector } from '../detector'
import { DetectorType } from '../../utils/enum'
import { clearLog, log, table } from '../../utils/log'
import { calculateTime, IS, createLargeObjectArray } from '../../utils/util'

export default class extends Detector {
  largeObjectArray: any
  maxPrintTime: number = 0

  constructor() {
    super({
      type: DetectorType.Performance,
      enabled: IS.chrome || !IS.mobile,
    })
  }

  init() {
    this.maxPrintTime = 0
    this.largeObjectArray = createLargeObjectArray()
  }

  detect() {
    const tablePrintTime = calculateTime(() => {
      table(this.largeObjectArray)
    })
    const logPrintTime = calculateTime(() => {
      log(this.largeObjectArray)
    })
    this.maxPrintTime = Math.max(this.maxPrintTime, logPrintTime)

    clearLog()

    if (tablePrintTime === 0 || this.maxPrintTime === 0) return false

    if (tablePrintTime > this.maxPrintTime * 10) {
      this.onDevToolOpen()
    }
  }
}
