export enum DetectorType {
  Unknown = -1,
  RegToString = 0,
  DefineId,
  Size,
  DateToString,
  FuncToString,
  Debugger,
  Performance,
  DebugLib,
}
