import { clearLog, log } from '../../utils/log'
import { Detector } from '../detector'
import { DetectorType } from '../../utils/enum'
import { IS } from '../../utils/util'

export default class extends Detector {
  date: Date = new Date()
  count: 0 = 0

  constructor() {
    super({
      type: DetectorType.DateToString,
      enabled: !IS.iosChrome && !IS.iosEdge,
    })
  }

  init() {
    this.count = 0
    this.date = new Date()
    this.date.toString = () => {
      this.count++
      return ''
    }
  }

  detect() {
    this.count = 0
    log(this.date)
    clearLog()
    if (this.count >= 2) {
      this.onDevToolOpen()
    }
  }
}
